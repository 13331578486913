<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Asignar Alertas</strong>
      </CCardHeader>
      <CCardBody>
        <div>
          <CRow>
            <CCol sm="2">
              <select id="alerts" class="form-select" v-on:change="getAlertId">
                <option
                  v-for="alert in this.alerts"
                  :key="alert.alert_id"
                  :value="alert.alert_id"
                >{{ alert.alert_title }}</option>
              </select>
            </CCol>
            <CCol sm="6">
              <CInput id="alert_limit" description="Límite" type="number" size="sm" enabled value="0" min="0" @change="checkField()"/>
              </CCol>
            <CCol >
              <CButton class="btn btn-success active" id="assignButton" disabled @click="assignAlert()">
                Asignar
              </CButton>
            </CCol>
          </CRow>
          <CRow class="justify-content-center">
            <CCol
              md="9"
              class="p-4"
            >
              <CAlert color="danger" dismissible :show.sync="showParametersError" close-button>
                  <strong>AVISO</strong>
                  {{errorText}}
                  <CProgress
                    :max="10"
                    :value="showParametersError"
                    height="3px"
                    color="danger"
                    animated
                  />
                </CAlert>
            </CCol>
          </CRow>
        </div>
        
      </CCardBody>
    </CCard>
    <CCard v-if="userAlerts.length > 0">
      <CCardHeader>
        <strong>Alertas actuales de {{user.name}} {{user.surname}}</strong>
      </CCardHeader>
      <CCardBody>
        <div v-for="(alert, index) of userAlerts" :key="index">
          <CRow>
         <CCol sm="8">
          <CInput :id="'campo_'+alert.alert_id" description="Límite" :label="alert.alert_title" :value="alert.alert_limit" horizontal size="sm" disabled/>    
        
         </CCol>
         <CCol sm="2">
           <CButton class="btn btn-danger active"  enabled @click="deleteAlert(alert.id_user_alert)">
            Eliminar
          </CButton>
         </CCol>
        </CRow>      
        </div>
      </CCardBody>
    </CCard>
    <div class="d-flex justify-content-center">
      <CButton class="btn btn-secondary" @click="back()">
        Volver
      </CButton>
    </div> 
  </div>
</template>

<script>
import {getAlertsData, checkUser, getUserDataPerID, getUserAlerts} from '../../utils/utils';
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import config from "@/config";
export default {
  name: "assignAlerts",
  data() {
    return {
      alerts: [],
      userAlerts:[],
      user: [],
      alertLimit: 0,
      URL_user_id:null,
      alertIDSelection:null,
      showParametersError: 0,
      errorText: 'Campos vacios o erroneos',
      LIMIT: 99999999,
    };
  },
  created() {
    if (!checkUser()) return;
    this.receiveData();
  },
  methods: {
    receiveData(){
      this.getAlerts();
      this.getUserID();
      this.getUser();
      this.getUserAlertsData();
    },
    /**
     * Función en la que se obtienen las alertas y que también
     * llama el metodo receiveData para cargarlos.
     *
     * @returns {object[]} Las alertas obtenidas.
     */
    getAlerts() {
      let alertData = getAlertsData();
      alertData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        let element = {};
        let data = [];
        result.alerts.forEach(alert => {
          element = {};
          element.alert_id = alert.alert_id;
          element.alert_title = alert.alert_title;
          data.push(element);
        });
        this.alerts = data;
        this.alertIDSelection=this.alerts[0].alert_id

      });
    },
    /**
     * Función en la que se obtiene el id del usuario pasado por la URL
     *
     */
    getUserID(){
      if (!this.$route.params.id==this.URL_user_id) return;
      this.URL_user_id=this.$route.params.id;
    },
    
    /**
     * Función en la que se obtiene los datos del usuario actual
     *
     */
    getUser(){
      let userData = getUserDataPerID(this.URL_user_id);
      userData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.user=result.user;
      });
    },
    
    /**
     * Función en la que se obtienen las alertas del usuario actual
     *
     */
    getUserAlertsData(){
      let alertData = getUserAlerts(this.URL_user_id);
      alertData.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.userAlerts=result.alerts;
      });
    },
    checkField(){
      const button = document.getElementById('assignButton');
      let limite = document.getElementById("alert_limit").value;
      if (limite.length>0 && limite>0){
        button.disabled= false;
        button.style.opacity="1";
      }else{
        button.disabled= true;
        button.style.opacity=".5";
      }
    },
    getAlertId(){
      this.alertIDSelection = event.target.value;
    },
    assignAlert() {
      let alert_limit = document.getElementById("alert_limit").value;
      if (alert_limit<0){
        this.errorText="El límite no puede ser negativo";
        this.showParametersError = 10;
        return;
      }
      if (alert_limit>this.LIMIT){
        this.showParametersError = 10;
        this.errorText='El límite es demasiado alto'
        return;
      } 
      const token = localStorage.token;
        axios.post(config.BACK_IP + "/alerts/" + this.URL_user_id,
        {
          user_id: this.URL_user_id,
          alert_id: this.alertIDSelection,
          alert_limit: alert_limit,
        }, 
        {headers: {token: token,}})
        .then(() => {
          this.getUserAlertsData();
        })
        .catch((error) => {
          if(error.response) {
            if(error.response.status === 401) {
              localStorage.removeItem("token");
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/pages/404";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
    },
    deleteAlert(id_user_alert) {
      const token = localStorage.token;
        axios.delete(config.BACK_IP + "/user/alerts/", { data: {id: id_user_alert}, headers: {token: token}})
        .then(() => {
          this.getUserAlertsData();
        })
        .catch((error) => {
          if(error.response) {
            if(error.response.status === 401) {
              localStorage.removeItem("token");
              window.location = "#/pages/login";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
    },
    back() {
      this.$router.push("/user/");
    },
  },
};
</script>